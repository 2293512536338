import React from "react";
// import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Leave Us A Review | DeBlanc + Murphy"
        description="Share your experience with DeBlanc + Murphy! Your feedback helps us improve. We'd love to hear from you, submit a review today."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pb-20 pt-16 md:pb-32 md:pt-20">
        <div className="container">
          <div className="mx-auto max-w-3xl">
            <iframe
              src="https://deblanc-reviews-platform.flywheelsites.com?xasdcdve"
              title="Reviews Platform"
              width="100%"
              height="1500px"
              border="0"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

// export const query = graphql`
//   {

//   }
// `;

export default Page;
